.plans-container{
    display: flex;
    flex-direction: column;
    gap: 5rem;
    margin-top: -4rem;
    padding: 0 2rem;
    position: relative;
}
/*Title of the section*/
.plans-header{
    display: flex;
    justify-content: center;
    font-size: 3rem;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
    gap: 5rem;

}

.plan-cards{
    display: flex;
    gap: 3rem;
    justify-content: center;
    align-items: center;
}
/*each plan card*/
.plan-category{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    background: var(--caloryCard);
    padding: 2rem;
    color: white;
    width: 15rem;
}
/*Highlight the premium plan*/
.plan-category:nth-child(2){
    background: var(--planCard);
    transform: scale(1.1);
}
.plan-category:nth-child(2)>svg{
    fill: white;
}
.plan-category:nth-child(2)>button{
    color: var(--orange);
}
.plan-category:nth-child(2)>button:hover{
    color: white;
}

/*icon of each plan*/
.plan-category>:nth-child(1){
    height: 2rem;
    width: 2rem;
    fill: var(--orange);
}
/*title of each plan*/
.plan-category>:nth-child(2){
    font-weight: bold;
    font-size: 1rem;
}
/*price of each plan*/
.plan-category>:nth-child(3){
    font-size: 3rem;
    font-weight: bold;
}
/*container of the features*/
.plan-category>.features{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
/*each feature*/
.plan-category>.features>.ft{
    display: flex;
    gap: 1rem;
}
/*icon of the features*/
.plan-category>.features>.ft>img{
    height: 1rem;
    width: 1rem;
}

.benefits{
    display: flex;
    gap: .5rem;
    align-items:center;
    font-size: .8rem;
}
.benefits>img{
    height: .8rem;
    width: .8rem;
}

.plans-blur-1{
    width: 32rem;
    height: 23rem;
    top: 6rem;
    left: 0;
}

.plans-blur-2{
    width: 32rem;
    height: 23rem;
    top: 6rem;
    right: 0;
}

@media screen and (max-width: 768px){
    .plans-header{
        flex-direction: column;
        gap: 1rem;
        font-size: x-large;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
    }
    .plan-cards{
        flex-direction: column;
        gap: 1rem;
    }
    .plan-cards>:nth-child(2){
        transform: none;
    }
}